import debounce from "./debounce"

class SearchForm {
  static placeholder = "<td class='empty' colspan='3'>Loading...</td>"
  constructor(form) {
    this.form = $(form)
    this.searchField = this.form.find(":input:not(:hidden)")
    this.table = this.form.find("table")
    this.addTriggers()
  }

  addTriggers() {
    let klass = this

    klass.searchField.on("keyup", debounce(function(e){
      if (e.keyCode != 13)
        klass.search()
    }, 300))

    klass.searchField.on("keydown", function(e){
      if (e.keyCode == 13)
        e.preventDefault()
    })
  }

  search() {
    let klass     = this
    let query     = $.trim(klass.searchField.val())
    let path      = klass.form.attr("action")
    let table     = klass.table
    let container = table.find("tbody")


    if (query !== "") {
      table.removeClass("is-hidden")

      // show placeholder while content loads
      container.html(klass.constructor.placeholder)

      $.get(path, {q: query}, function(response){
        container.html(response)
      })
    } else {
      table.addClass("is-hidden")
    }
  }
}

export class ChangeForm {
  constructor(form, searchForm) {
    this.form = $(form)
    this.searchForm = $(searchForm)
    this.newAddressText = this.form.find(".household-unit-change-address-text")
    this.switchHouseholdUnitBtn = this.newAddressText.siblings(":button")

    this.addTriggers()
  }

  addTriggers() {
    let klass = this

    this.switchHouseholdUnitBtn.on("click", function(){
      klass.searchForm.removeClass("is-hidden")
      klass.form.addClass("is-hidden")
    })

    this.searchForm.on("click", ".household-unit-change-select-button", function(e){
      let button = $(e.currentTarget)

      klass.searchForm.addClass("is-hidden")
      klass.form.removeClass("is-hidden")
      klass.form.find(":input:hidden[name$='[household_unit_id]']").val(button.data("household-unit-id"))

      klass.newAddressText.text(button.closest("tr").find("td:first").text())
    })
  }
}

export class ChangeHouseholdForm {
  static init() {
    let searchForm = document.querySelector(".household-unit-search-form")
    let changeForm = document.querySelector(".household-unit-change-form")

    if (searchForm) {
      new SearchForm(searchForm)

      if (changeForm)
        new ChangeForm(changeForm, searchForm)
    }
  }
}

export class LeaveRejoinHouseholdForm {
  static init() {
    $("[class^='change-household-unit-button-']").on("click", function(e) {
      let type = $(this).attr("class").replace("edit-cancel", "").split("-")[4]
      let id = $(this).attr("class").replace("edit-cancel", "").split("-")[5]
      let form = $(".household-unit-"+type+"-form-fields-"+id)

      if ($(this).hasClass("edit-cancel")) {
        form.addClass("is-hidden")
        form.find("td.household-unit-"+type+"-fields").addClass("is-hidden")
        $(this).first().removeClass("edit-cancel")
      } else {
        form.removeClass("is-hidden")
        form.find("td.household-unit-"+type+"-fields").removeClass("is-hidden")
        $(this).first().addClass("edit-cancel")
      }
    })
  }
}
