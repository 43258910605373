import {AjaxForm} from "./ajax_form"

var Modal, ModalInitializer;

window.destroyModal = function(modal) {
  return modal.dialog("destroy").remove();
};

window.Modals = (function() {
  Modals.INIT_CLASS = "modalized";
  Modals.TITLE = "Title"

  function Modals(trigger) {
    this.trigger = $(trigger).addClass(this.constructor.INIT_CLASS);
    Modals.TITLE = this.trigger.data("modal-title")
    this.modal = null;
    this.trigger.on("click", (function(_this) {
      return function() {
        return _this.open();
      };
    })(this));
    this.open();
  }

  Modals.prototype.build = function() {
    var modal;
    modal = $("<div class='modal' style='display: none;' title='"+ this.constructor.TITLE +"'></div>");
    $("body").append(modal);
    this.loadContent(modal);
    return modal.dialog({
      autoOpen: false,
      modal: true
    });
  };

  Modals.prototype.open = function() {
    if (!((this.modal != null) && $.contains(document.documentElement, this.modal[0]))) {
      this.modal = this.build();
    }
    return this.modal.dialog("open");
  };

  Modals.prototype.loadContent = function(target) {
    var path;
    path = this.trigger.data("modal-path");
    $.get(path, (function(_this) {
      return function(response) {
        target.html(response);
        _this.modal.dialog("option", "width", "auto");
        return _this.trigger.trigger("dialog.contentLoaded", _this.modal);
      };
    })(this));
    return true;
  };

  return Modals;

})();

window.ModalInitializer = (function() {
  function ModalInitializer() {
    $(document).on("click", ".ui-widget-overlay", function(event) {
      var target;
      target = $(event.currentTarget);
      return $(".modal:visible").dialog("close");
    });
    $(document).on("click", "[data-modal-path]", function(event) {
      var target;
      target = $(event.currentTarget);
      if (!target.hasClass(Modals.INIT_CLASS)) {
        new Modals(target);
        AjaxForm.initAll()
      }
    });
  }
  return ModalInitializer;
})();