export class DonorForms {
  static initAll() {
    $(".donor-form").each(function(){
      new DonorForms(this)
    })

    $(".donation-form").each(function(){
      new DonationForm(this)
    })
  }

  constructor(form) {
    this.form = $(form)
    this.donorTypeField = this.form.find("[name$='[donor_type_id]']")
    this.nameField = this.form.find("[name$='[name]']")
    this.contactFirstField = this.form.find("[name$='[contact_first_name]']")
    this.contactLastField = this.form.find("[name$='[contact_last_name]']")
    this.init()
  }

  init() {
    let klass = this

    klass.donorTypeField.on("change", function(){
      klass.toggleRequired()
    }).trigger("change")
  }

  toggleRequired() {
    let isIndividual = this.donorTypeField.find("option:selected").data("individual") || false

    if (isIndividual) {
      this.contactFirstField.attr("required", "required").closest(".field").prev("label").find(".has-text-danger").show()
      this.contactLastField.attr("required", "required").closest(".field").prev("label").find(".has-text-danger").show()
      this.nameField.removeAttr("required").closest(".field").hide().prev("label").hide()
    } else {
      this.contactFirstField.removeAttr("required").closest(".field").prev("label").find(".has-text-danger").hide()
      this.contactLastField.removeAttr("required").closest(".field").prev("label").find(".has-text-danger").hide()
      this.nameField.attr("required", "required").closest(".field").show().prev("label").show()
    }
  }


  // static placeholder = "<td class='empty' colspan='3'>Loading...</td>"
  // constructor(form) {
  //   this.form = $(form)
  //   this.searchField = this.form.find(":input:not(:hidden)")
  //   this.table = this.form.find("table")
  //   this.addTriggers()
  // }

  // addTriggers() {
  //   let klass = this

  //   klass.searchField.on("keyup", debounce(function(e){
  //     if (e.keyCode != 13)
  //       klass.search()
  //   }, 300))

  //   klass.searchField.on("keydown", function(e){
  //     if (e.keyCode == 13)
  //       e.preventDefault()
  //   })
  // }

  // search() {
  //   let klass     = this
  //   let query     = $.trim(klass.searchField.val())
  //   let path      = klass.form.attr("action")
  //   let table     = klass.table
  //   let container = table.find("tbody")


  //   if (query !== "") {
  //     table.removeClass("is-hidden")

  //     // show placeholder while content loads
  //     container.html(klass.constructor.placeholder)

  //     $.get(path, {q: query}, function(response){
  //       container.html(response)
  //     })
  //   } else {
  //     table.addClass("is-hidden")
  //   }
  // }
}

class DonationForm {
  constructor(form){
    this.form = $(form)
    this.donorForm = $(".donor-form")
    this.donorFormInputs = this.donorForm.find("input,select")
    this.donorRequiredInputs = this.donorForm.find(".required")

    this.donorField = this.form.find("[name$='[donor_id]']")
    this.donorTypeField = this.form.find("[name$='[donor_type_id]']")
    this.nameField = this.form.find("[name$='[name]']")
    this.contactFirstField = this.form.find("[name$='[contact_first_name]']")
    this.contactLastField = this.form.find("[name$='[contact_last_name]']")
    this.init()
  }

  init() {
    let klass = this

    klass.donorField.on("change", function(){
      if (klass.donorField.find("option:selected").data("new") || false){
        klass.donorForm.show()
        klass.donorFormInputs.removeAttr("disabled")
        klass.make_required(klass.donorRequiredInputs)

        if(klass.donorTypeField.find("option:selected").data("individual") || false) {
          klass.make_required(klass.contactFirstField)
          klass.make_required(klass.contactLastField)
        }
        else {
          klass.make_required(klass.nameField)
        }
      }
      else {
        klass.donorFormInputs.attr("disabled", "disabled")
        klass.remove_required(klass.donorRequiredInputs)

        klass.remove_required(klass.nameField)
        klass.remove_required(klass.contactFirstField)
        klass.remove_required(klass.contactLastField)
        klass.donorForm.hide()
      }
    }).trigger("change")
  }

  make_required(field){
    field.attr("required", "required").closest(".field").prev("label").find(".has-text-danger").show()
  }

  remove_required(field){
    field.removeAttr("required").closest(".field").prev("label").find(".has-text-danger").hide()
  }
}