export class Tabs {
  static init(){
    $(".tabs").each(function(){
      new Tabs(this)
    })
  }

  constructor(tabs) {
    this.tabs = $(tabs)
    this.init()
  }

  init() {
    let startTab = window.location.hash.substr(1)

    this.tabs.find("[data-target]").on("click", function(e){
      let trigger = $(e.currentTarget);
      let tab = $(".tab[data-tab='" + trigger.data("target") + "']");

      trigger.parent("li").addClass("is-active").siblings("li").removeClass("is-active")
      tab.addClass("is-active").siblings(".tab").removeClass("is-active")
    })

    this.tabs.find("[data-target='" + startTab + "']").trigger("click")
  }
}
