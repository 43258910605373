import {AjaxForm} from "./ajax_form";

export class Home {
  static init() {
    $(".dashboard").each(function(){
      new Dashboard(this)
    });
  }
}

class Dashboard {
  constructor(dashboard) {
    this.dashboard = $(dashboard)
    this.content = this.dashboard.find(".dashboard-content")
    this.filtersForm = this.dashboard.find(".dashboard-form")

    this.setTriggers()
  }

  setTriggers() {
    let klass = this

    klass.filtersForm.on("submit", function(e){
      e.preventDefault()
      klass.content.html("Loading...")
      klass.loadContent(klass.filtersForm.serialize())
    })

    klass.dashboard.on("click", ".pagination a:not(.is-current)", function(e){
      e.preventDefault()
      klass.content.html("Loading...")
      klass.loadContent($(e.currentTarget).attr("href").split("?")[1])
    })

    if (klass.dashboard.data("dashboard") === "pending_encounters") {
      klass.initAssistanceForms()
    }
  }

  initAssistanceForms() {
    this.dashboard.find(".assistance-payment-form").each(function(_, form){
      new AssistancePaymentForm(form)
    })
  }

  loadContent(data) {
    let klass = this

    $.get("/", data, function(resp){
      klass.content.html(resp['html'])

      if (klass.dashboard.data("dashboard") === "pending_encounters") {
        klass.initAssistanceForms()
      }
    }, 'json')
  }
}

class AssistancePaymentForm {
  constructor(form) {
    let klass = this

    this.form = $(form)
    this.ajaxForm = new AjaxForm(this.form, {afterSubmit: function(resp){
      let formRow = klass.form.closest("tr")

      if (resp['remove_from_list']) {
        formRow.prev("tr").find(".payment-form-toggle").replaceWith("<span class='has-text-success'><i class='fas fa-check'></i> Added</span>")
        formRow.remove()
      } else {
        formRow.addClass("is-hidden")
      }
    }})

    this.setTriggers()
  }

  setTriggers() {
    let klass = this

    klass.form.closest("tr").prev("tr").find(".payment-form-toggle").on("click", function(e){
      e.preventDefault()
      $(e.currentTarget).closest("tr").next("tr").toggleClass("is-hidden")
    })

    klass.form.find(".assistance-form-cancel").on("click", function(e){
      e.preventDefault()
      $(e.currentTarget).closest("tr").toggleClass("is-hidden")
    })
  }
}