export class AssistanceForms {
  static initAll() {
    $(document).find("form.assistance-form").each(function(){
      new AssistanceForms(this)
    })

    $(document).find("form.batch-payment-form").each(function(){
      new BatchPaymentForm(this)
    })
  }

  constructor(form) {
    this.form = $(form)
    this.needTypeField = this.form.find("[name$='[need_type_id]']")
    this.landlordFields = this.form.find(".landlord-fields")
    this.assistanceToggle = this.form.find("[name$='[no_assistance]']")
    this.paymentDateField = this.form.find("[name$='[payment_date]']")
    this.receiptDateField = this.form.find("[name$='[receipt_date]']")
    this.fundingSrcField = this.form.find("[name$='[funding_source_id]']")
    this.checkField = this.form.find("[name$='[check_number]']")
    this.billFile = this.form.find("[name$='[bill_file]']")

    this.partialPaymentToggle = this.form.find("[name$='[partial_payment]']")
    this.partialPaymentFields = this.form.find(".partial-payment-fields")
    this.partialPaymentAmount = this.form.find("[name$='[client_paid_amount]']")
    this.partialPaymentReceipt = this.form.find("[name$='[receipt_file]']")
    this.partialPaymentPaid = this.form.find("[name$='[partial_payment_paid]']")

    this.addTriggers()
  }

  addTriggers() {
    let klass = this

    let paymentFields = klass.paymentDateField.add(klass.receiptDateField).add(klass.fundingSrcField)
    klass.assistanceToggle.on("change", function(){
      if (klass.assistanceToggle.is(":checked")) {
        paymentFields.attr("disabled", "disabled")
      } else {
        paymentFields.removeAttr("disabled")
      }
    }).trigger("change")

    klass.needTypeField.on("change", function(){
      if ($(this).val() == ""){
        klass.checkField.data("need-type", false)
        klass.landlordFields.hide()
      }
      else {
        klass.checkField.data("need-type", true)
        if ($(this).find(":selected").text() == "Rent")
          klass.landlordFields.show()
        else
          klass.landlordFields.hide()
      }
    }).trigger("change")

    klass.billFile.on("change", function(){
      klass.checkField.data("bill-file", true)
    })

    let partialFields = klass.partialPaymentAmount.add(klass.partialPaymentReceipt).add(klass.partialPaymentPaid)
    klass.partialPaymentToggle.on("change", function(){
      if (klass.partialPaymentToggle.is(":checked")) {
        klass.partialPaymentFields.show()
        partialFields.removeAttr("disabled")
        klass.checkField.data("partial-payment", true)
      }
      else {
        partialFields.attr("disabled", "disabled")
        klass.partialPaymentFields.hide()
        klass.checkField.data("partial-payment", false)
      }
    }).trigger("change")

    klass.partialPaymentReceipt.on("change", function(){
      klass.partialPaymentPaid.prop("checked", true).trigger("change")
    })

    klass.partialPaymentPaid.on("change", function(){
      if ($(this).is(":checked"))
        klass.checkField.data("receipt-file", true)
      else
        klass.checkField.data("receipt-file", false)
    })

    // let checkNumberRequirements = klass.needTypeField.add(klass.billFile).add(klass.partialPaymentToggle).add(klass.partialPaymentReceipt).add(klass.partialPaymentPaid)
    // checkNumberRequirements.on("change", function(){
    //   let check = klass.checkField
    //   if (!check.data("missing-intake-documents") && check.data("need-type") && check.data("bill-file") && (!check.data("partial-payment") || check.data("receipt-file"))) {
    //     check.removeAttr("disabled")
    //   } else {
    //     check.attr("disabled", "disabled")
    //   }
    // })
  }
}

class BatchPaymentForm {
  static disabledClass = "batch-row-disabled"

  constructor(form) {
    this.form = $(form)
    this.addTriggers()
  }

  addTriggers() {
    let klass = this

    this.form.find("[name$='[update]']").on("change", function(){
      let toggle = $(this)
      toggle.closest("tr").toggleClass(klass.constructor.disabledClass, !toggle.is(":checked"))
    })

    this.form.find("[name$='[payment_date]'], [name$='[check_number]']").on("focus", function(){
      let row = $(this).closest("tr")
      let toggle = row.find("[name$='[update]']")

      if (row.hasClass(klass.constructor.disabledClass))
        toggle.prop("checked", true).trigger("change")
    })
  }
}
