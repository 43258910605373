export class ProgramActivityForm {
  static init() {
    let form = document.querySelector(".program-activity-form")

    if (form)
      new ProgramActivityForm(form)
  }

  constructor(form) {
    this.form = $(form)
    this
    this.addTriggers()
  }

  addTriggers() {
    let activityTypeField = this.form.find("[name$='[program_activity_type_id]']")
    let activityOtherDescriptionField = this.form.find("[name$='[other_activity_type_description]']")

    activityTypeField.on("change", function(){
      if (activityTypeField.find("option:selected").data("other")) {
        activityOtherDescriptionField.closest(".column").show()
        activityOtherDescriptionField.attr("required", "required")
      } else {
        activityOtherDescriptionField.closest(".column").hide()
        activityOtherDescriptionField.removeAttr("required")
      }
    }).trigger("change")
  }
}