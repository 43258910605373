import {AjaxForm} from "./ajax_form";
import SignaturePad from 'signature_pad'
import debounce from "./debounce"

export class ClientForms {
  static initAll() {
    $(document).find("form.client-intake-form").each(function(){
      new ClientIntakeForm(this)
    })

    $(document).find("form.client-profile-form").each(function(){
      new ClientProfileForm(this)
    })

    $(document).find("form.client-budget-form").each(function(){
      new ClientBudgetForm(this)
    })

    $(document).find(".client-filters").each(function(){
      new ClientFilters()
    })

    $(document).find("input[class*='client_intake_document_expiration_date']").each(function(){
      new ClientDocumentWarning()
    })

    $(document).find("table.intake-document-table").each(function(){
      new ClientEditDocumentForm()
    })

    $(document).find("form.new-client-document-form").each(function(){
      new ClientDocumentForm()
    })

    $(document).find("form.edit-client-status-form").each(function(){
      new ClientStatusForm()
    })

    $(document).find("form.release-form").each(function(){
      new ClientReleaseForm(this)
    })
  }
}

class ClientProfileForm {
  constructor(form) {
    this.form = $(form)
    this.initDisabilityFields()
    this.initEmployerFields()
    this.initLandlordFields()
    this.initResponsiblePartyFields()
  }

  initDisabilityFields() {
    new ClientDisabilityFields(this.form)
  }

  initEmployerFields() {
    new ClientEmployerFields(this.form)
  }

  initLandlordFields() {
    $("[name='client[homeowner]']").on("click", function(){
      if ($(this).is(":checked"))
        $(".landlord-fields").hide()
      else
        $(".landlord-fields").show()
    })
  }

  initResponsiblePartyFields() {
    $(".client-responsible-party").on("change", function(){
      if ($(this).is(":checked"))
        $(".responsible-party-fields").show()
      else
        $(".responsible-party-fields").hide()
    }).trigger("change")
  }
}

class ClientBudgetForm {
  constructor(form) {
    this.form = $(form)
    this.init()
  }

  init() {
    let klass = this
    klass.form.on("change", "[data-budget-category]", function(){
      klass.addSubmitConfirmation()
      klass.addPlaceholder($(this))
      klass.recalculateTotals()
    })

    klass.recalculateTotals()
  }

  recalculateTotals() {
    let grossTotal = 0
    let expenseTotal = 0
    let netTotal = 0

    this.form.find("[data-budget-category][value!='']").each(function(idx, field){
      let f = $(field)
      let val = f.val()

      if ($.isNumeric(val)) {
        val = parseFloat(val)

        if (f.data("budget-category") === "income") {
          grossTotal = grossTotal + val
          netTotal = netTotal + val
        } else {
          expenseTotal = expenseTotal + val
          netTotal = netTotal - val
        }
      }
    })

    this.form.find("[data-budget-total='monthly-gross']").text("$" + this.parseValue(grossTotal))
    this.form.find("[data-budget-total='yearly-gross']").text("$" + this.parseValue(grossTotal * 12))
    this.form.find("[data-budget-total='monthly-expenses']").text("$" + this.parseValue(expenseTotal))
    this.form.find("[data-budget-total='yearly-expenses']").text("$" + this.parseValue(expenseTotal * 12))

    let monthlyNet = this.form.find("[data-budget-total='monthly-net']")
    let yearlyNet = this.form.find("[data-budget-total='yearly-net']")

    if (netTotal < 0){
      netTotal = -netTotal
      monthlyNet.text("-$" + this.parseValue(netTotal)).addClass("danger-text")
      yearlyNet.text("-$" + this.parseValue(netTotal * 12)).addClass("danger-text")
    }
    else{
      monthlyNet.text("$" + this.parseValue(netTotal)).removeClass("danger-text")
      yearlyNet.text("$" + this.parseValue(netTotal * 12)).removeClass("danger-text")
    }
  }

  // only add confirmation on change
  addSubmitConfirmation() {
    let submit = this.form.find(":submit[data-confirm-on-change]")

    submit.attr('data-confirm', submit.data('confirm-on-change'))
  }

  addPlaceholder(field) {
    if (field.val() == "")
      field.val(0)
  }

  parseValue(value) {
    value = value.toFixed(2)
    value = value.replace(".00", "") // use whole value unless decimal is used
    value = value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
    return value
  }
}

class ClientEditDocumentForm {
  constructor() {
    if (!$('table').hasClass('toggle-activated')) {
      $(".intake-document-table").on("click", ".document-edit-button", function(e) {
        let form = $(this).parent().siblings("form")
        if ($(this).hasClass("edit-cancel")) {
          form.siblings(".client-document-form-labels").show()
          form.siblings(".client-document-form-fields").hide()
          $(this).first().removeClass("edit-cancel")
        } else {
          form.siblings(".client-document-form-labels").hide()
          form.siblings(".client-document-form-fields").show()
          $(this).first().addClass("edit-cancel")
        }
      })
      $('table').addClass('toggle-activated')
    }

    $("input[class*='client_intake_document_expiration_date']").trigger("change")

    $("#primary_client_no_proof_of_income").on("click", function(){
      $(this).parents("form").submit()
    })
  }
}

class ClientEmployerFields {
  constructor(form) {
    this.form = $(form)
    this.list = this.form.find(".client-employer-list")
    this.addBtn = this.list.find(".add-association")
    this.init()
  }

  init() {
    let klass = this

    klass.reindexFields()

    klass.addBtn.on("mouseup", function(){
      setTimeout(function(){
        klass.reindexFields()
      }, 100)
    })

    klass.list.on("click", ".remove-association", function(e){
      let trigger = $(e.currentTarget)
      let fields = trigger.closest(".client-employer-fields")

      fields.slideUp(function(){
        if (fields.data("persisted")) {
          let idField     = fields.next("[name$='[id]']")
          let deleteField = idField.clone()

          deleteField.attr("name", function(idx, val) { return val.replace(/\[id\]/, "[_destroy]") })
          fields.replaceWith(deleteField)
        } else {
          fields.remove()
        }

        klass.reindexFields()
      })
    })
  }

  reindexFields() {
    this.list.find(".client-employer-fields").each(function(idx, fields){
      if (idx > 0)
        $(fields).find(".label:first").text("Employer #" + (idx + 1))
    })
  }
}

class ClientDisabilityFields {
  constructor(form) {
    this.form = $(form)
    this.disField = this.form.find("[name$='[disability]']")
    this.recField = this.form.find("[name$='[disability_received]']")
    this.amtField = this.form.find("[name$='[disability_amount]']")
    this.required = this.disField.attr("required")
    this.init()
  }

  init() {
    let klass = this
    this.disField.add(this.recField).on("change", function(){
      klass.toggleRequired()
    })

    klass.toggleRequired()
  }

  toggleRequired() {
    if (this.disField.val() === "true") {
      this.enableField(this.recField)

      if (this.recField.val() === "true") {
        this.enableField(this.amtField)
      } else {
        this.disableField(this.amtField)
      }
    } else {
      this.disableField(this.recField.add(this.amtField))
    }
  }

  disableField(field) {
    field.removeAttr("required").closest(".column").hide()
  }

  enableField(field) {
    field.attr("required", this.required).closest(".column").show()
  }
}

class ClientIntakeForm {
  static initAll() {
    $(document).find("form.client-intake-form").each(function(){
      new ClientIntakeForm(this)
    })
  }

  constructor(form) {
    this.form = $(form)
    this.init()
  }

  init() {
    if (this.form.hasClass("search-form"))
      new ClientSearchForm(this.form)

    if (this.form.hasClass("validate-form"))
      new ClientValidateForm(this.form)

    if (this.form.hasClass("intake-document-form"))
      new ClientIntakeDocumentForm(this.form)

    if (this.form.hasClass("household-form"))
      new ClientHouseholdForm(this.form)

    if (this.form.hasClass("assistance-form") || this.form.hasClass("referrals-form"))
      new ClientEntryForm(this.form)
  }
}

class ClientEntryForm {
  constructor(form) {
    let klass = this
    let form_name = "assistance"
    klass.nextStep = "Referrals"

    if (form.hasClass("referrals-form")){
      form_name = "referrals"
      klass.nextStep = "Follow-up Survey"
    }

    klass.containerSelector = "."+form_name+"-container"
    klass.tableSelector = "."+form_name+"-table"
    klass.placeholderSelector = "."+form_name+"-placeholder"
    klass.continueLinkSelector = "."+form_name+"-continue-link"
    klass.formTriggerSelector = "."+form_name+"-form-trigger"
    klass.formCancelSelector = "."+form_name+"-form-cancel"

    this.ajaxForm = new AjaxForm(form, {afterSubmit: function(resp) { klass.addEntry(resp) }})
    this.form = $(form)
    this.cancel = this.form.find(klass.formCancelSelector)
    this.container = this.form.closest(klass.containerSelector)
    this.triggers = this.container.find(klass.formTriggerSelector)
    this.table = this.container.find(klass.tableSelector)
    this.placeholder = this.container.find(klass.placeholderSelector)

    this.addTriggers()
  }

  addEntry(newEntry) {
    this.table.removeClass("is-hidden").find("tbody").prepend(newEntry["html"])
    this.placeholder.remove()

    this.ajaxForm.reset()
    this.hideForm()

    this.updateContinueButton()
  }

  addTriggers() {
    let klass = this

    klass.triggers.on("click", function(){
      klass.showForm()
      klass.hidePartialPayment()
    })

    klass.cancel.on("click", function(){
      klass.hideForm()
    })
    this.updateContinueButton()
  }

  updateContinueButton() {
    if ($(this.placeholderSelector).length < 1)
      $(this.continueLinkSelector).addClass("button is-success is-rounded").html("Continue to "+this.nextStep+" &#8594;")
  }

  showForm() {
    this.form.removeClass("is-hidden")
    this.placeholder.addClass("is-hidden")
    this.triggers.addClass("is-hidden")
  }

  hideForm() {
    this.form.addClass("is-hidden")

    if (this.table.is(":visible")) {
      this.triggers.removeClass("is-hidden")
    } else {
      this.triggers.not(this.container.find(".navbar " + this.formTriggerSelector)).removeClass("is-hidden")
      this.placeholder.removeClass("is-hidden")
    }
  }

  hidePartialPayment() {
    this.partialPaymentFields = this.form.find(".partial-payment-fields")
    this.partialPaymentAmount = this.form.find("[name$='[client_paid_amount]']")
    this.partialPaymentReceipt = this.form.find("[name$='[receipt_file]']")
    this.partialPaymentPaid = this.form.find("[name$='[partial_payment_paid]']")
    this.checkField = this.form.find("[name$='[check_number]']")
    let partialFields = this.partialPaymentAmount.add(this.partialPaymentReceipt).add(this.partialPaymentPaid)

    // console.log("hide partial payment")

    partialFields.attr("disabled", "disabled")
    this.partialPaymentFields.hide()
    this.checkField.data("partial-payment", false)
  }
}

class ClientHouseholdForm {
  static containerSelector = ".household-container"
  static tableSelector = ".household-table"
  static formTriggerSelector = ".household-form-trigger"
  static formCancelSelector = ".household-form-cancel"

  constructor(form) {
    let klass = this

    this.ajaxForm = new AjaxForm(form, {afterSubmit: function(resp) { klass.addHousehold(resp) }})
    this.form = $(form)
    this.cancel = this.form.find(klass.constructor.formCancelSelector)
    this.container = this.form.closest(klass.constructor.containerSelector)
    this.triggers = this.container.find(klass.constructor.formTriggerSelector)
    this.table = this.container.find(klass.constructor.tableSelector)

    this.addTriggers()
  }

  addHousehold(newHousehold) {
    this.table.find("tbody").prepend(newHousehold["html"])
    this.ajaxForm.reset()
    this.hideForm()
    this.updateHouseholdCounts(newHousehold["json"])
  }

  addTriggers() {
    let klass = this

    klass.triggers.on("click", function(){
      klass.showForm()
    })

    klass.cancel.on("click", function(){
      klass.hideForm()
    })
  }

  showForm() {
    this.form.removeClass("is-hidden")
  }

  hideForm() {
    this.form.addClass("is-hidden")
  }

  updateHouseholdCounts(client) {
    this.addOne($(".number-in-household"))

    let today = new Date()

    if (new Date(client.dob) < new Date(today.getFullYear()-18, today.getMonth(), today.getDate()))
      this.addOne($(".number-of-adults"))
    else
      this.addOne($(".number-of-children"))

    if (client.dependent)
      this.addOne($(".number-of-dependents"))
  }

  addOne(selector) {
    selector.text(parseInt(selector.text()) + 1)
  }
}

class ClientIntakeDocumentForm {
  static containerSelector = ".intake-document-container"
  static formFieldsSelector = ".intake-document-form-fields"
  static formCancelSelector = ".intake-document-form-cancel"
  static formTriggerSelector = ".intake-document-form-trigger"
  static tableSelector = ".intake-document-table"
  static placeholderSelector = ".intake-document-placeholder"
  static idPlaceholderSelector = ".intake-document-placeholder-identification"
  static incomePlaceholderSelector = ".intake-document-placeholder-proof_of_income"
  static noProofOfIncome = "#primary_client_no_proof_of_income"
  static continueLinkSelector =".intake-documents-continue-link"

  constructor(form) {
    let klass = this

    this.ajaxForm = new AjaxForm(form, {afterSubmit: function(resp) { klass.addDocument(resp) }})
    this.form = $(form)
    this.fields = this.form.find(this.constructor.formFieldsSelector)
    this.cancel = this.form.find(this.constructor.formCancelSelector)
    this.container = this.form.closest(this.constructor.containerSelector)
    this.triggers = this.container.find(this.constructor.formTriggerSelector)
    this.table = this.container.find(this.constructor.tableSelector)
    this.placeholder = this.container.find(this.constructor.placeholderSelector)

    this.addTriggers()
  }

  addDocument(newDoc) {
    this.table.removeClass("is-hidden").find("tbody").prepend(newDoc["html"])
    this.placeholder.remove()

    this.ajaxForm.reset()
    this.hideForm()

    this.updateContinueButton()
    new ClientDocumentWarning()
  }

  addTriggers() {
    let klass = this

    klass.triggers.on("click", function(){
      klass.showForm()
    })

    klass.cancel.on("click", function(){
      klass.hideForm()
    })
    this.updateContinueButton()
  }

  updateContinueButton() {
    if ($(this.constructor.idPlaceholderSelector).length == 0 && ($(this.constructor.incomePlaceholderSelector).length == 0 || $(this.constructor.noProofOfIncome).is(":checked")))
      $(this.constructor.continueLinkSelector).addClass("button is-success is-rounded").html("Continue to Client Release &#8594;")
    else if ($(this.constructor.idPlaceholderSelector).length == 0)
      $(this.constructor.continueLinkSelector).removeClass("button is-success is-rounded").html("Skip Proof of Income & Continue to Client Release &#8594;")
    else if ($(this.constructor.incomePlaceholderSelector).length == 0 || $(this.constructor.noProofOfIncome).is(":checked"))
      $(this.constructor.continueLinkSelector).removeClass("button is-success is-rounded").html("Skip ID/Driver's License & Continue to Client Release &#8594;")
  }

  showForm() {
    this.fields.removeClass("is-hidden")
    this.placeholder.addClass("is-hidden")
    this.triggers.addClass("is-hidden")
  }

  hideForm() {
    this.fields.addClass("is-hidden")

    if (this.table.is(":visible")) {
      this.triggers.removeClass("is-hidden")
    } else {
      this.triggers.filter(".file").removeClass("is-hidden")
      this.placeholder.removeClass("is-hidden")
    }
  }
}

// new intake document form from client show page
class ClientDocumentForm {
  constructor() {
    $("#client_intake_document_doc_type").on("change", function(){
      if ($(this).val() == "proof_of_income") {
        let today = new Date()
        $("[class*='client_intake_document_expiration_date']").val((today.getFullYear()+1)+'-'+String(today.getMonth() + 1).padStart(2, '0')+'-'+String(today.getDate()).padStart(2, '0'))
        $(".client_intake_document_proof_of_income_type").show()
        $("#client_intake_document_proof_of_income_type_id").attr("required", "required")
      }
      else {
        $("[class*='client_intake_document_expiration_date']").val('')
        $("#client_intake_document_proof_of_income_type_id").removeAttr("required")
        $(".client_intake_document_proof_of_income_type").hide()
      }

      if ($(this).val() == "supplemental"){
        $("[class*='client_intake_document_expiration_date']").removeAttr("required")
        $("label[for='client_intake_document_expiration_date']").find("span").hide()
      }
      else{
        $("[class*='client_intake_document_expiration_date']").attr("required", "required")
        $("label[for='client_intake_document_expiration_date']").find("span").show()
      }
    })
  }
}

class ClientReleaseForm {
  constructor(form) {
    let klass = this

    this.form = $(form)
    this.submits = this.form.find(":submit")
    this.canvas = this.form.find(".sign-canvas")
    this.skip = this.form.find(".skip-release-form")

    this.signaturePad = new SignaturePad(this.canvas[0], {
      onBegin: function(){
        klass.submits.removeAttr("disabled")
        klass.skip.hide()
      }
    })

    this.resizeCanvas()
    this.addTriggers()
  }

  resizeCanvas() {
    let ratio = Math.max(window.devicePixelRatio || 1, 1)
    let canvas = this.canvas[0]

    canvas.style.width = '100%';
    canvas.style.height = canvas.offsetWidth / 2.5 + "px";

    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);

    this.signaturePad.clear()
  }

  updateSignatureField() {
    let sig = this.signaturePad.toDataURL()

    this.form.find(":input:hidden[name$='[signature]']").val(sig)
  }

  addTriggers() {
    let klass = this

    $(window).on("resize", function(){
      klass.resizeCanvas()
    })

    this.submits.on("click", function(){
      klass.updateSignatureField()
    })

    this.form.find(".release-form-reset").on("click", function(){
      klass.signaturePad.clear()
      klass.submits.attr("disabled", "disabled")
      klass.skip.show()
    })
  }
}

class ClientSearchForm {
  constructor(form) {
    this.form = $(form)
    this.addressFields = this.form.find(".client-intake-address-fields")
    this.submits = this.form.find(":submit")
    this.inputs = this.form.find(":input[name^='client_search']:not(:hidden)")
    this.listTable = $("table#client-list")

    this.addTriggers()
  }

  addTriggers() {
    let klass = this

    klass.form.find(".address-toggle-button").on("click", function(e){
      klass.toggleAddressSection();

      e.preventDefault();
    })

    klass.submits.hide()
    klass.inputs.on("keyup change", debounce(function(e){
      if (e.keyCode != 13)
        klass.runSearch()
    }, 300)).trigger("keyup")

    klass.inputs.on("keydown", function(e){
      if (e.keyCode == 13)
        e.preventDefault()
    })

    klass.submits.on("click", function(){
      if (klass.addressFields.is(":hidden"))
        klass.addressFields.find("[required]").removeAttr("required")
    })

    // if (this.getUsedFields().filter(":not([name$='[state]'])").length > 0)
    //   this.runSearch()
  }

  getUsedFields() {
    return this.inputs.filter(function(idx, el){ return(el.type === "checkbox" ? el.checked : (!!el.value)) })
  }

  runSearch() {
    let klass = this
    let usedFields = this.getUsedFields()

    // only run search if one of the text fields populated
    if (usedFields.filter("[type!='checkbox']").length > 0) {
      klass.listTable.find("tbody")
                    .children("tr").hide()
                    .filter("tr.placeholder-loading").show()

      $.get("/client_intakes/search", usedFields.serialize(), function(response){
        klass.listTable.find("tbody tr").hide()
        klass.listTable.find("tbody").prepend(response)
        klass.submits.removeAttr("disabled")
      });

      klass.submits.attr("disabled", "disabled")
      klass.submits.show()
    } else { // no values to search with
      klass.listTable.find("tbody")
                    .children("tr").hide()
                    .filter("tr.placeholder-first").show()

      klass.submits.removeAttr("disabled")
      klass.submits.hide()
    }
  }

  toggleAddressSection() {
    let klass = this

    klass.form.find(".address-toggle-button").text(function(){
      return klass.addressFields.toggleClass("is-hidden").is(":visible") ? "[-] Hide Address Information" : "[+] Show Address Information";
    })
  }
}

class ClientValidateForm {
  constructor(form) {
    this.form = $(form)
    this.inputs = $(".client-validate")
    this.addTriggers()
  }

  addTriggers() {
    let klass = this

    this.inputs.on("change", debounce(function(e){
      klass.runClientSearch()
    }, 300)).trigger("change")
  }

  runClientSearch() {
    let usedFields = this.inputs.filter(function(idx, input){
      if (idx == 2)
        return input.value != "" && input.value.split("-")[0][0] != "0" //4 numbers in the year before searching
      else
        return !!input.value
    })
    let notes = $(".duplicate-client-note")
    let submitBtn = this.form.find(":submit")
    let newRecord = $("[name='client_id'").length == 0

    if (newRecord && usedFields.length > 2) {
      $.get("/client_intakes/validate", usedFields.serialize(), function(duplicate_clients){
        if (!duplicate_clients) {
          notes.text("")
          submitBtn.removeAttr("data-confirm")
        }
        else if (duplicate_clients.length > 1) {
          notes.append("There are multiple existing clients with this name and date of birth. If you wish to create a new client with this information, click 'Create Client' below.<br> If you would like to edit one of the existing clients click here to go back to the client intakes: <a href='/client_intakes/start'>Search Client Intakes</a><br>")
          submitBtn.attr("data-confirm", "There are multiple existing clients with this name and date of birth.\n\nAre you sure you want to create a new client?")
        }
        else {
          notes.append("There is already an existing client with this name and date of birth. If you wish to create a new client with this information, click 'Create Client' below.<br> If you would like to edit the existing client click here: <a href='/client_intakes/manage_client?client_id="+duplicate_clients+"'>Edit Existing Client</a><br>")
          submitBtn.attr("data-confirm", "There is already an existing client with this name and date of birth.\n\nAre you sure you want to create a new client?")
        }
      });
    }
    else {
      notes.text("")
      submitBtn.removeAttr("data-confirm")
    }
  }
}

class ClientStatusForm {
  constructor() {
    if (!$('table').hasClass('toggle-activated')) {
      $(".client-status-archive-button").on("click", function(e) {
        // console.log($(this).parent().siblings())
        let form = $(this).parent().siblings("form")
        if ($(this).hasClass("edit-cancel")) {
          form.siblings(".client-status-form-labels").show()
          form.siblings(".client-status-form-fields").hide()
          $(this).first().removeClass("edit-cancel")
        } else {
          form.siblings(".client-status-form-labels").hide()
          form.siblings(".client-status-form-fields").show()
          $(this).first().addClass("edit-cancel")
        }
      })
      $('table').addClass('toggle-activated')
    }
  }
}

class ClientFilters {
  constructor() {
    $(".last_name_letter").on("click", function(){
      $(".li-last-name-letter").removeClass("is-active")
      $(this).parent().addClass("is-active")
      $("#last_name_hidden_field").val($(this).text())
      $("#clear-last-name").show()
    });

    $("#clear-last-name").on("click", function(){
      $(".li-last-name-letter").removeClass("is-active")
      $("#last_name_hidden_field").val(null)
      $("#clear-last-name").hide()
    })

    if (window.location.search.includes("?")) {
      const yOffset = 140
      const element = document.getElementById('client-list-content');
      const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset;

      window.scrollTo({top: y, behavior: 'smooth'})
    }
  }
}

class ClientDocumentWarning {
  constructor() {
    $("p[id^='expired-document-warning']").hide();

    $("input[class*='client_intake_document_expiration_date']").on("change", function(){
      let klass = $(this).removeClass("parsley-success").attr("class").split("_")
      let warning = $("#expired-document-warning")

      if (klass.length >= 6)
        warning = $("#expired-document-warning-" + klass[5])

      if (Date.parse($(this).val()) > Date.parse("1900-01-01") && Date.parse($(this).val()) < Date.now())
        warning.show()
      else
        warning.hide()
    })
  }
}
