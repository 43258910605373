// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@fortawesome/fontawesome-pro/css/all"
import $ from 'jquery'
import {Tabs} from "./tabs"
import {Home} from "./home"
import {Report} from "./reports"
import {AjaxForm} from "./ajax_form"
import {AssistanceForms} from "./assistance_forms"
import {ClientForms} from "./client_forms"
import {ClientMergeForm} from "./client_merge_form"
import {ChangeHouseholdForm} from "./change_household_form"
import {LeaveRejoinHouseholdForm} from "./change_household_form"
import {DonorForms} from "./donor_forms"
import {ProgramActivityForm} from "./program_activity_form"
import {Flash} from "./flash"
import {DragAndDrop} from "./drag_and_drop"
import Parsley from 'parsleyjs'
import Select2 from 'select2'
import {AdminPages} from "./admin_pages"
import "./modals"

Rails.start()
ActiveStorage.start()

var loadShared = function(){
  // Check for click events on the navbar burger icon
  $(document).on("click", ".navbar-burger", function(){
    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    $(this).toggleClass("is-active")
    $(".navbar-menu#" + $(this).data("target")).toggleClass("is-active")
  });

  $(document).on("click", ".summary-extension-trigger", function(){
    let summary = $(this).closest(".summary")
    let extension = summary.find(".summary-extended")

    extension.toggleClass("is-active")

    $(this).text(extension.hasClass("is-active") ? "[-] Hide Extra Info" : "[+] View More Info")
  });

  $(document).on("click", "[data-toggle]", function(){
    $("#"+$(this).data("toggle")).toggle();
  });

  $(document).on("change", "[data-switch]", function(){
    var t = $(this)
    $("#"+t.data("switch")).toggle(t.is(":checked"))
  })

  $(document).on("change", ".file input[type='file']", function(){
    AjaxForm.updateFileFieldText(this)
  }).find(".file input[type='file']").trigger("change");

  $(document).on("click", ".tooltip", function(e){
    $(e.currentTarget).toggleClass("active")
  });

  // clone association fields
  $("form").on("click", ".add-association", function(event){
    event.preventDefault()

    var button = $(event.currentTarget)
    var time = new Date().getTime()
    var regexp = new RegExp(button.data("hash").id, 'g')
    var content = $(button.data("hash").fields.replace(regexp, time))

    if (button.data("append-to")) {
      $(button.data("append-to")).append(content)
    } else {
      button.before(content).prev()
    }

    button.trigger("association.added", content)
  })

  loadParsley()

  Tabs.init()
  AjaxForm.initAll()
  AssistanceForms.initAll()
  ClientForms.initAll()
  ClientMergeForm.init()
  ChangeHouseholdForm.init()
  LeaveRejoinHouseholdForm.init()
  DonorForms.initAll()
  ProgramActivityForm.init()
  Report.initAll()
  Flash.displayAll()
  DragAndDrop.init()
  AdminPages.init()
  Home.init()
  loadMultiselect();
  noNegativeNumberInput();
}

var loadParsley = function(){
  $(document).find("form[data-validate='true']").each(function(idx, form){
    $(form).parsley()
  })
}

var loadJavascript = function(){
  loadShared(); // this is js that runs on every page

  $(document).trigger("js.load"); // global trigger
  // $(document).trigger(Env.controller() + "/" + Env.action() + ".load"); // page-specific trigger

  return true;
};

var initJavascript = function(){
  var body = document.getElementsByTagName("BODY")[0];
  var init = body.getAttribute("data-js-init") !== "true";

  if (init) {
    body.setAttribute("data-js-init", "true")
    loadJavascript();
    return true;
  }

  return false;
};

var loadMultiselect = function() {
  $("select[multiple='multiple']").select2({
    placeholder: "All",
    closeOnSelect: false
  })

  $("[class^='multiselect-']").on("change", function(){
    let $clear_btn = $(".clear-" + $(this).attr("class").replace(" report-selected", "").replace(" select2-hidden-accessible", ""))
    let $input = $(this).parent().find(".select2-selection--multiple")
    if($(this).val().length > 0) {
      $input.removeClass("multiselect-default-height")
      $clear_btn.show()
    }
    else {
      $input.addClass("multiselect-default-height")
      $clear_btn.hide()
    }
  }).trigger("change")

  $("[class^='clear-multiselect-']").on("click", function(){
    let selector = $(this).attr("class").match(/clear-[a-zA-Z\-]+/)[0].replace("clear-", "")
    let $filter = $("." + selector)

    $filter.val([])
    $filter.trigger("change")
  })
}

var noNegativeNumberInput = function() {
  $("input[data-validate-positive-number='true']").on("keydown", function(e){
    return e.key != "-"
  })
}

$(document).on("js.load", function() {
  new ModalInitializer;
});

document.addEventListener('DOMContentLoaded', initJavascript);
