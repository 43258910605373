export class Flash {
  static autohideDuration = 5 * 1000 // 5 seconds
  static displayAll() {
    $(".flash-input").each(function(){
      let input = $(this)
      let msg = input.val()
      let options = input.data()

      new Flash(msg, options)
    })
  }

  constructor(message, options) {
    if (options == undefined)
      options = {}

    let klass = this

    klass.type = options['type'] || 'info'
    klass.autohide = options['autohide']

    if (klass.autohide == undefined)
      klass.autohide = klass.type == "notice"


    klass.flash = klass.buildFlash(message)
    klass.container = $(".flash-messages").find("ul")

    // initial display
    klass.show()

    klass.flash.on("click", ".delete", function(){ klass.hide() })
  }

  buildFlash(message) {
    let icon = this.getIcon()
    let cssClass = this.getCssClass()
    return $("<li class='notification " + cssClass + "'><button class='delete'></button>" + icon + " " + message + "</li>")
  }

  getCssClass() {
    let cssClass = "is-info"

    if (this.type == "alert") {
      cssClass = "is-danger"
    } else if (this.type == "notice") {
      cssClass = "is-success"
    }

    return cssClass
  }

  getIcon() {
    let iconName = "info-circle"

    if (this.type == "alert") {
      iconName = "exclamation-triangle"
    } else if (this.type == "notice") {
      iconName = "check-circle"
    }

    return "<i class='fa fa-" + iconName + "'></i>"
  }

  show() {
    let klass = this

    klass.container.prepend(klass.flash.hide())
    klass.flash.slideDown("fast")

    if (klass.autohide){
      setTimeout(function(){
        klass.hide()
      }, Flash.autohideDuration)
    }
  }

  hide() {
    let flash = this.flash

    flash.slideUp(function(){
      flash.remove()
    })
  }
}