import { DirectUpload } from "@rails/activestorage"

export class FileUpload {
  static upload(input, onSuccess) {
    new FileUpload(input, onSuccess).upload()
  }

  constructor(input, onSuccess) {
    this.input = $(input)
    this.onSuccess = onSuccess
    this.queue = new Array
    this.uploadUrl = this.input.data("directUploadUrl")
  }

  upload() {
    let i, file, len
    let klass = this
    let files = klass.input[0].files

    for (i = 0, len = files.length; i < len; i++) {
      file = files[i]
      klass.queue.push(file)
    }

    for (i = 0, len = klass.queue.length; i < len; i++) {
      file = klass.queue[i]

      new DirectUpload(file, klass.uploadUrl, klass).create(function(error, blob){
        if (error) {
          // Handle the error
          // Shouldn't submit form since file doesn't get removed from queue
          alert(error)
        } else {
          // Add an appropriately-named hidden input to the form with a
          // value of blob.signed_id so that the blob ids will be
          // transmitted in the normal upload flow
          let hiddenField = $("<input type='hidden'/>")

          hiddenField.attr("value", blob.signed_id).attr("name", klass.input.attr('name'))
          klass.input.before(hiddenField)

          // remove file from queue
          klass.queue.splice(klass.queue.indexOf(file), 1)
          klass.afterUpload()
        }
      })
    }
  }

  directUploadWillStoreFileWithXHR(request) {
    new FileUploadProgress(this.input, request.upload)
  }

  afterUpload() {
    if (this.queue.length <= 0) {
      // disable input so only hidden fields are submitted
      this.input.attr("disabled", "disabled")

      if ($.isFunction(this.onSuccess))
        this.onSuccess()
    }
  }
}

class FileUploadProgress {
  constructor(input, upload) {
    this.upload = upload
    this.input = input
    this.progressBar = $("<progress class='progress is-success' value='0' max='100'>0%</progress>")

    this.start()
  }

  start() {
    let klass = this

    klass.input.closest(".file").after(this.progressBar)
    klass.upload.addEventListener("progress", function(event){ klass.updateProgress(event) })
  }

  updateProgress() {
    let percent = event.loaded / event.total * 100

    this.progressBar[0].value = percent
    this.progressBar.text(percent + "%")
  }
}

