export class AdminPages {
  static init() {
    $(document).find(".accordion").each(function(){
      new AdminPages(this)
    })
  }

  constructor() {
    this.addAccordion()
  }

  addAccordion() {
    $("#income-budget-types-header, #fixed-expense-budget-types-header, #flexible-expense-budget-types-header").on("click", function(){
      var filter = $(this).attr("id").replace("-header", "")
      $("."+filter).slideToggle()

      if ($("#"+filter+"-arrow").hasClass("fa-caret-right"))
        $("#"+filter+"-arrow").removeClass("fa-caret-right").addClass("fa-caret-down")
      else
        $("#"+filter+"-arrow").removeClass("fa-caret-down").addClass("fa-caret-right")
    })
  }
}
