import debounce from "./debounce"

class SearchForm {
  static placeholder = "<td class='empty' colspan='3'>Loading...</td>"
  constructor(form) {
    this.form = $(form)
    this.searchField = this.form.find(":input:not(:hidden)")
    this.table = this.form.find("table")
    this.addTriggers()
  }

  addTriggers() {
    let klass = this

    klass.searchField.on("keyup", debounce(function(e){
      if (e.keyCode != 13)
        klass.search()
    }, 300))

    klass.searchField.on("keydown", function(e){
      if (e.keyCode == 13)
        e.preventDefault()
    })
  }

  search() {
    let klass     = this
    let query     = $.trim(klass.searchField.val())
    let path      = klass.form.attr("action")
    let table     = klass.table
    let container = table.find("tbody")


    if (query !== "") {
      table.removeClass("is-hidden")

      // show placeholder while content loads
      container.html(klass.constructor.placeholder)

      $.get(path, {q: query}, function(response){
        container.html(response)
      })
    } else {
      table.addClass("is-hidden")
    }
  }
}

export class MergeForm {
  constructor() {
    $("#deletable-household-btn, #deletable-encounter-btn, #deletable-referral-btn, #deletable-document-btn").on("click", function(){
      let category = $(this).attr("id").split("-")[1]
      let deletable_number = parseInt($("#deletable-" + category + "-count").text())
      let total = $("#total-" + category + "-count")

      if ($(this).prop("checked"))
        total.text(parseInt(total.text()) + deletable_number)
      else
        total.text(parseInt(total.text()) - deletable_number)
    })

    $("input[name='household'").on("change", function(){
      if ($(this).val() == "retain"){
        $(".household-merge-preview-svg").show()
        $(".merge-svg").hide()
      }
      else if($(this).val() == "merge"){
        $(".household-merge-preview-svg").show()
        $(".merge-svg").show()
      }
      else{
        $(".household-merge-preview-svg").hide()
      }
    })
  }
}

export class ClientMergeForm {
  static init() {
    let searchForm = document.querySelector(".client-search-form")

    if (searchForm)
      new SearchForm(searchForm)
    else if ($(".client-merge-form").length > 0)
      new MergeForm()
  }
}
