export class Report {
  static errorMsg = "<span><i class='fa fa-warning'></i> An error occurred while loading this report.</span>"
  static initAll() {
    $(document).find(".report-form").each(function(){
      new Report
    })
  }

  constructor() {
    this.report = $(".report-container")
    this.addFilterTriggers()
    this.loadReport()
  }

  loadReport() {
    let ph = this.placeholder()

    if (ph.length > 0)
      this.poll(ph.data("load-path"))
  }

  poll(url) {
    let klass = this

    $.get(url, function(resp, textStatus, xhr){
      if (xhr.status == 200) {
        let content = $(resp)

        klass.placeholder().replaceWith(content)
        klass.addExpenseAccordion()
        klass.addSortByTriggers()
      } else if  (xhr.status == 202) {
        setTimeout(function(){
          klass.poll(url)
        }, 2000)
      }
    }).fail(function(){
      klass.placeholder().addClass("error-msg").html(Report.errorMsg)
    })
  }

  placeholder() { return this.report.find(".report-placeholder") }

  addFilterTriggers() {
    let klass = this;
    ["#demographic-filter", "#household-filter", "#encounters-filter", "#client-filter"].forEach(function(id) {
      if ($(id+" .report-selected").length == 0){
        klass.toggleArrow(id)
      }
    })

    $("#assistance-filter-header, #demographic-filter-header, #household-filter-header, #encounters-filter-header, #client-filter-header").on("click", function(){
      var filter = $(this).attr("id").replace("-header", "")
      klass.toggleArrow('#'+filter, true)
    })
  }

  addExpenseAccordion() {
    let klass = this
    $(document).find(".expense-report-form").each(function(){
      $("[id^='expense-report-need-type-']").on('click', function(){
        let num = $(this).attr("id").split('-')[4]
        klass.toggleArrow("#expense-report-need-"+num)
      }).trigger("click")
    })
  }

  addSortByTriggers() {
    $(".reports-sort-by-arrow").on("click", function(){
      let form = $(this).parents("div#report-content").siblings("div.block").find("form")
      form.find(".report-sort-by-filter").val($(this).data("sort"))
      form.find(".report-sort-asc-filter").val($(this).data("asc").toString())
      form.submit()
    })
  }

  toggleArrow(id, slide=false) {
    if (slide)
      $(id).slideToggle()
    else
      $(id).toggle()

    if ($(id+"-arrow").hasClass("fa-caret-right"))
      $(id+"-arrow").removeClass("fa-caret-right").addClass("fa-caret-down")
    else
      $(id+"-arrow").removeClass("fa-caret-down").addClass("fa-caret-right")
  }
}