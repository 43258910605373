import dragula from "dragula";
import {Flash} from "./flash"
import autoScroll from "dom-autoscroller"

export class DragAndDrop {
	static init() {
		$(document).find(".dragula").each(function(){
			new DragAndDrop(this)
		})
	}

	constructor() {
		this.addDragAndDrop()
	}

	addDragAndDrop() {
		var container = document.getElementById('container');
		var rows = container.children
		var ids = $(rows).map(function(_, r){
			return $(r).data("id")
		})

		var resource = window.location.pathname.split('/')[1];

		var sortableContainer = dragula([container], {mirrorContainer: container})

		var scroll = autoScroll([window, container],{
			margin: 20,
			maxSpeed: 8,
			scrollWhenOutside: true,
			autoScroll: function(){
				return this.down && sortableContainer.dragging;
			}
		});

		sortableContainer.on('drop', function() {
			var ids = $(rows).map(function(_, r){
				return $(r).data("id")
			})
			let ids_array = ids.toArray();

			// remove duplication caused by mirrorContainer
			ids_array.pop()

			$.post("/" + resource + "/edit_order", {ids: ids_array}, function(){
				new Flash("Successfully updated " + resource.replace(/_/g, ' ') + " list!", {type: "notice"})
			})
		});
	}
}
