import Inputmask from "inputmask";
import {FileUpload} from "./file_upload";
import {Flash} from "./flash";

export class AjaxForm {
  static errorsSelector = "form-errors"
  static phoneMask = new Inputmask("(999) 999-9999")
  static dateMask = new Inputmask({
    regex: "(0\\d|1[0-2])/(0\\d|1\\d|2\\d|3[0-1])/\\d{4}",
    placeholder: "mm/dd/yyyy"
  })

  static updateFileFieldText(input) {
    let files = [];

    // get list of names of selected files
    Object.keys(input.files).forEach(function(idx){
      files.push(input.files[idx].name)
    });

    // show selected files by input
    $(input).closest(".file").find(".file-name").text(function(){
      return files.length > 0 ? files.join(", ") : "No file selected."
    })
  }

  static initAll() {
    $(document).find("form.ajax-form[data-skip-ajax-init='false']").each(function(){
      new AjaxForm(this)
    })
  }

  constructor(form, options) {
    this.form = $(form)
    this.options = options || {}
    this.addTriggers()
  }

  addTriggers() {
    let klass = this

    this.form.on("click", ".form-errors .delete", function(e){
      e.preventDefault()
      $(this).closest(".form-errors").slideUp()
    })

    this.form.on("submit", function(e){
      e.preventDefault()
      e.stopPropagation()

      klass.uploadFilesAndSubmit()
    })

    this.form.find(".phone-input").each(function(idx, input){
      AjaxForm.phoneMask.mask(input)
    })

    this.form.find(".date-input").each(function(idx, input){
      AjaxForm.dateMask.mask(input)
    })

    this.form.find(".attributes_city_id").on("change", function(e){
      $(this).parents("div.column").next().find(".attributes_county_id").val($(this).find(":selected").data("county-id"))
    })
  }

  reset() {
    this.form.find(".file").each(function(){
      let field = $(this)
      let input = field.find("input[type='file']")
      let progress = field.siblings(".progress")

      if (progress.length > 0) { // look for progress bars to see if input had files uploaded first
        field.find(":input:hidden").remove()
        progress.remove()
        input.removeAttr("disabled").val('') // reset file input

        AjaxForm.updateFileFieldText(input[0])
      }
    })

    this.form.trigger("reset")
  }

  submit() {
    this.disableAllSubmitButtons()

    let klass = this
    let form  = klass.form
    let data  = form.serialize()
    let url   = form.attr("action")

    $.post(url, data, function(resp){
      if (resp != undefined){
        if (resp.redirect_to != undefined){
          window.location.href = resp.redirect_to
        } else {
          if ($.isFunction(klass.options.afterSubmit))
            klass.options.afterSubmit(resp)

          if (resp.flash != undefined) {
            let type = Object.keys(resp.flash)[0]
            let text = Object.values(resp.flash)[0]

            new Flash(text, {type: type})
          }
        }
      }

      form.trigger("ajax:success", resp)
    }, "json").fail(function(resp){
      // if (resp.status === 422)
      //   window.location.href = "/errors/change_rejected"

      let errors = resp.responseJSON["errors"]

      // reload recaptcha
      if (form.find(".g-recaptcha").length > 0)
        grecaptcha.reset()

      klass.addErrors(errors)
    }).always(function(resp){
      // re-enable submit buttons, if staying on current page
      if (resp.redirect_to == undefined){
        klass.enableAllSubmitButtons()
      }

      form.trigger("ajax:complete", resp)
    })
  }

  uploadFilesAndSubmit() {
    this.disableAllSubmitButtons()

    let klass = this
    let inputsWithFiles = klass.form.find("input[type='file'][data-direct-upload-url]:enabled")
                             .filter(function(idx, input){ return input.files.length > 0 })

    // just submit form unless files need to be uploaded
    if (inputsWithFiles.length < 1) {
      klass.submit()
    } else {
      // for input in inputsWithFiles
      inputsWithFiles.each(function(){
        let input = this
        FileUpload.upload(this, function(){
          inputsWithFiles = inputsWithFiles.not(input)

          // only submit form once all file inputs have their files uploaded
          if (inputsWithFiles.length <= 0){
            klass.submit()
          }
        })
      })
    }
  }

  // display errors list on form
  addErrors(errors) {
    if ($.isArray(errors))
      errors = this.buildErrorMsgs(errors)

    // remove existing errors
    this.form.find("." + AjaxForm.errorsSelector).remove()

    // add new errors to DOM, but hidden from view
    this.form.prepend(errors.hide())

    // show errors
    errors.slideDown("fast", function(){
      // show if they're not in view
      errors[0].scrollIntoView()
    })
  }

  // converts array of strings into error list
  buildErrorMsgs(messages) {
    let title     = messages.length + " " + (messages.length != 1 ? "Errors" : "Error") + " Prevented This Form From Saving:"
    let errors    = $("<div class='" + AjaxForm.errorsSelector + " notification is-danger is-light'></div>")
    let errorList = $("<ul></ul>")

    $.each(messages, function(_, msg){
      errorList.append("<li>" + msg + "</li>")
    })

    return errors.append("<button class='delete' type='button'></button>").append("<p><strong>" + title + "</strong></p>").append(errorList)
  }

  enableAllSubmitButtons() {
    let klass = this
    this.form.find(":submit:disabled").each(function(){
      klass.enableBtn($(this))
    })
  }
  disableAllSubmitButtons() {
    let klass = this
    this.form.find(":submit:enabled").each(function(){
      klass.disableBtn($(this))
    })
  }

  enableBtn(btn) {
    let origText = btn.attr("data-original-text")

    this.setBtnValue(btn, origText)
    btn.removeAttr("disabled")
  }

  disableBtn(btn) {
    let origText = this.getBtnValue(btn)
    let disableText = btn.data("disable-with")

    if (disableText == undefined)
        disableText = "Submitting..."

    btn.attr("disabled", "disabled")
       .attr("data-original-text", origText)

    this.setBtnValue(btn, disableText)
  }

  getBtnValue (btn) { return (btn[0].tagName == "BUTTON") ? btn.html() : btn.val() }
  setBtnValue (btn, val) { return (btn[0].tagName == "BUTTON") ? btn.html(val) : btn.val(val) }
}
